@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,100;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Maven+Pro:100,200,300,400,500,600,700&subset=latin-ext");

body,
button,
p,
span,
h1,
h2,
h3 {
  font-family: "Roboto", "Maven Pro", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
input,
select,
textarea {
  color: #333;
}

button {
  outline: none;
  border: none;
}

input {
  font-family: "Roboto", "Maven Pro", "Helvetica", "Arial", sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #F5F5F5 inset !important;
    background-clip: content-box !important;
}